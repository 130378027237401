<script lang="ts">
	import { meta } from "tinro";
	import { getIndex } from "./indexService";
	import Download from "./download/Index.svelte";
	import Error from "./Error.svelte";
	import Loader from "./Loader.svelte";

	const route = meta();
</script>
<div class="content-wrapper">
	<section aria-live="assertive" class="content-width-limit">
		{#await getIndex(route.params.id)}
		<Loader/>
		{:then entries}
		<Download downloadId={route.params.id} {entries}/>
		{:catch}
		<Error/>
		{/await}
	</section>
</div>

<style>
	section {
		padding-top: var(--gap);
		margin: 0 auto;
		width: 100%;
		min-height: 0;

		display: grid;
	}
</style>
