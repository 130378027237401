<script lang="ts">
	import { Type } from "./ToastType";
	import { fade } from "svelte/transition";

	const TOAST_TIMEOUT = 3000; // ms

	export let message: string = "";
	export let type: Type = Type.negative;
	export let visible: boolean = false;

	function hideDelayed() {
		setTimeout(() => { visible = false; }, TOAST_TIMEOUT);
	}
	$: toastType = type === Type.positive ? "positive" : "negative";
</script>

{#if visible}
<div class="toast-wrapper" in:fade on:introend={hideDelayed}>
	<div class="toast {toastType} flex-center">
		{message}
	</div>
</div>
{/if}

<style>
	.positive {
		background-color: var(--toast-positive-background-color);
		color: var(--toast-positive-text-color);
	}

	.negative {
		background-color: var(--toast-negative-background-color);
		color: var(--toast-negative-text-color);
	}

	.toast-wrapper {
		display: flex;
		justify-content: center;

		position: absolute;
		top: calc(-3 * var(--gap));
		width: 100%;
		z-index: 10;
	}

	.toast {
		padding: var(--gap);

		border-radius: 20px;

		min-width: 220px;
	}
</style>
