<script lang="ts">
	import { t } from "svelte-i18n";
	import IonosDesktop from "./ionos/Desktop.svelte";
	import StratoDesktop from "./strato/Desktop.svelte";
	import { currentTenant, Tenant } from "../tenant";
	import mediaService from "../ui/mediaService";
	import { ActionId } from "../tracking/actionId";
	import { referrerLinkBuilder } from "../tracking/referrerLinkBuilder";

	let href: string;
	$: href = referrerLinkBuilder($t("mainAdUrl"), ActionId.DESKTOP_AD);
</script>

<a
	class={$mediaService.classNames}
	{href}
	rel="noopener"
	target="_blank"
>
	{#if currentTenant === Tenant.Ionos}
	<IonosDesktop/>
	{:else}
	<StratoDesktop/>
	{/if}
</a>

<style>
	a > :global(image-preloader) {
		width: 100%;
		height: 100%;
	}

	a {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;

		overflow: hidden;
	}
</style>
