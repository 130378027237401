import getSanitizedConfigValue from "./util";

const rawConfig: Config = window.config;

const frozenConfig: Config = Object.freeze({
	RECEIVER_DOMAIN: getSanitizedConfigValue(rawConfig.RECEIVER_DOMAIN) || window.location.origin,
	SENDER_DOMAIN: getSanitizedConfigValue(rawConfig.SENDER_DOMAIN) || window.location.origin,
});

export default frozenConfig;
