<script type="ts">
	import { t } from "svelte-i18n";
	import { process } from "../../../i18n/processLinks";

	import termsAcceptedService from "../../termsAcceptedService";

	import acceptTermsImage from "./accept-terms.svg";

	function setTermsAccepted() {
		termsAcceptedService.setAccepted(true);
	}
</script>

<div class="accept-terms">
	<p class="teaser text-bold content-width-limit">{$t("send.intro.consentTitle")}</p>
	<p class="caption content-width-limit">{@html process($t("send.intro.consent"), { privacy: $t("privacyUrl"), termsOfUse: $t("termsUrl") })}</p>

	<div class="visual-container">
		<img src={acceptTermsImage} alt="">
	</div>

	<button class="primary" on:click={setTermsAccepted} data-qa="accept-terms">{$t("accept")}</button>
</div>

<style>
	img {
		margin: auto;
	}

	button {
		display: block;
		margin: calc(2 * var(--gap)) auto 0;
		width: 100%;
	}

	.accept-terms {
		display: grid;
		grid-template-rows: auto auto 1fr auto;
	}
</style>
