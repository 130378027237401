<script lang="ts">
	import { t } from "svelte-i18n";

	import quotaService from "../../quotaService";
	import formatBytes from "../../../ui/formatBytes";

	import FileSelect from "../FileSelect.svelte";

	import teaserImage from "./add-file-teaser.svg";
</script>

<section>
	<p class="teaser text-bold content-width-limit">{$t("send.intro.teaser", { values: { limit: $formatBytes($quotaService.limit) } })}</p>
	<p class="caption lifetime">{$t("send.intro.lifetime")}</p>

	<div class="visual-container">
		<img src={teaserImage} alt="">
	</div>

	<div class="file-select">
		<FileSelect class="button primary">{$t("send.selectFiles")}</FileSelect>
	</div>

</section>

<style>
	section {
		display: grid;
		grid-template-rows: auto auto 1fr auto;
	}

	.file-select :global(.button.primary) {
		margin: calc(2 * var(--gap)) auto 0;
	}
</style>
