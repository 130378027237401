<script lang="ts">
	import { t } from "svelte-i18n";
	import ErrorImage from "../assets/error.svg";
</script>

<section>
	<div class="caption text-bold content-width-limit">
		{$t("receive.error")}
	</div>
	<div class="visual-container">
		<img src={ErrorImage} alt="">
	</div>
</section>

<style>
	section {
		display: grid;
		grid-template-rows: auto 1fr;

		text-align: center;
	}

	:global(.media-desktop) section {
		padding-top: calc(2 * var(--gap));
	}

	.caption {
		white-space: break-spaces;
		margin: auto;
	}

	.visual-container {
		margin-top: calc(2 * var(--gap));
	}
</style>
