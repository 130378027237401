import { locale } from "svelte-i18n";
import { get } from "svelte/store";

import loadScript from "./loadScript";
import detectDeviceType from "./detectDeviceType";

const ACCOUNT_IDS = {
  de: "AW-813575410",
  en: "AW-809251524",
  es: "AW-813574886",
  fr: "AW-813575587",
  it: "AW-813576059",
};

declare global {
    interface Window { GOOGLE_DATALAYER: any[]; }
}
window.GOOGLE_DATALAYER = window.GOOGLE_DATALAYER || [];

function gtag(..._args: any[]) {
	window.GOOGLE_DATALAYER.push(arguments);
}

export default () => {
	const currentLocale = get(locale);
	const accountId = ACCOUNT_IDS[currentLocale] || ACCOUNT_IDS["de"];

	loadScript(`https://www.googletagmanager.com/gtag/js?id=${accountId}&l=GOOGLE_DATALAYER`);

	gtag("js", new Date(Date.now()));
	gtag("config", accountId);
	gtag("event", "ViewContent", {
		"send_to": accountId,
		"PageType": "ProductPage",
		"u1_ProductArea": "HiDrive Share",
		"u7_DeviceType": detectDeviceType(),
		"u10_Section": "share.hidrive",
	});
};
