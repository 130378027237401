<script lang="ts">
	import { t } from "svelte-i18n";
	import uploadService from "../uploadService";
	import quotaService from "../quotaService";
	import queueService from "../queueService";
	import formatBytes from "../../ui/formatBytes";
	import ProgressRing from "../../ui/ProgressRing.svelte";
	import progressVisual from "./progress-visual.svg";
	import Cancel from "./Cancel.svelte";

	$: uploadedBytes = $formatBytes($uploadService.bytesDone, 1);
	$: totalBytes = $formatBytes($quotaService.size, 1);
</script>

<section aria-live="assertive">
	<div class="labels">
		<div class="text-bold">{$t("progress.uploading")}</div>
		<div class="text-muted" aria-live="off">{$t("progress.fileCount", { values: { count: $queueService.length } })}</div>
		<div class="text-muted" aria-live="off">{$t("progress.bytes", { values: { totalBytes, uploadedBytes } })}</div>
	</div>
	<div class="progress">
		<ProgressRing max={$quotaService.size} value={$uploadService.bytesDone}/>
	</div>
	<div class="visual-container">
		<img src={progressVisual} alt=""/>
	</div>
	<Cancel/>
</section>

<style>
	section {
		text-align: center;

		display: grid;
		grid-template-rows: auto auto 1fr auto;
	}

	:global(.media-desktop) section {
		padding-top: calc(2 * var(--gap));
	}

	.labels > div {
		margin-bottom: calc(var(--gap) / 2);
	}

	.visual-container {
		margin: calc(2 * var(--gap)) auto;
	}
</style>
