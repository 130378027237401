import { ISubscription, Subscription } from "../../subscription";

export class TermsAcceptedService implements ISubscription<boolean> {
	private accepted: boolean = false;

	private subscription: Subscription<boolean>;
	public subscribe;

	constructor() {
		this.subscription = new Subscription(() => this.accepted);
		this.subscribe = (arg) => this.subscription.subscribe(arg);
	}

	setAccepted(value: boolean) {
		if (value !== this.accepted) {
			this.accepted = value;
			this.subscription.notify();
		}
	}
}
