const FILENAME_SPLIT_MATCHER = /(\.?[^.]*)\.?(.*)?/;

/**
 * Split filename into base name part and extension part
 *
 * @param {string} name file name
 * @returns {[string, string]} [basename, extension]
 */
function splitFileName(name: string): [string, string] {
	const [, baseName, extensionCandidate] = name.match(FILENAME_SPLIT_MATCHER);

	const extension = extensionCandidate ? `.${extensionCandidate}` : "";

	return [baseName, extension];
}

/**
 * Create name conflict resolver for a batch of files.
 *
 * @example
 * const resolver = nameConflictResolver();
 *
 * resolver("file.txt") // -> "file.txt"
 * resolver("file.txt") // -> "file(1).txt"
 * resolver("file.txt") // -> "file(2).txt"
 *
 * @returns {Function}
 */
export default function() {
	const usedNames: Record<string, true> = {};

	return function getConflictFreeName(name: string): string {
		const nameUsed = usedNames[name];

		if (!nameUsed) {
			usedNames[name] = true;
			return name;
		}

		let [baseName, extension] = splitFileName(name);
		let counter = 0;
		let nameCandidate;
		let candidateUsed;
		do {
			counter++;

			nameCandidate = `${baseName}(${counter})${extension}`;
			candidateUsed = usedNames[nameCandidate];
		} while (candidateUsed);

		usedNames[nameCandidate] = true;
		return nameCandidate;
	};
}
