<script lang="ts">
	import { t } from "svelte-i18n";
	import formatBytes from "../../ui/formatBytes";
	import Icon, { IconEnum } from "../../ui/icon/Icon.svelte";
	import type { IndexEntry } from "../indexService";

	export let entries: IndexEntry[];

	let count: number = entries.length;
	let size: number = entries.reduce((sum, entry) => sum + entry.size, 0);
</script>

<div class="info-text flex-center">
	<Icon icon={IconEnum.File}/>
	<span class="download-count">
		{$t("receive.info.count", { values: { count } })}
	</span>
	<span class="spacer">
		&#124;
	</span>
	<Icon icon={IconEnum.Drive}/>
	<span class="download-size">
		{$formatBytes(size, 1)}
	</span>
</div>

<style>
	.download-size,
	.download-count {
		margin-left: var(--half-gap);
	}

	.spacer {
		color: var(--spacer-color);
		margin-right: var(--half-gap);
		margin-left: var(--half-gap);
	}
</style>
