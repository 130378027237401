import type { IConsentService, ConsentResult } from "./consentServiceTypes";

export type { ConsentResult };

import StratoConsent from "./strato";
import IonosConsent from "./ionos";

import { currentTenant, Tenant } from "../tenant";

let instance: IConsentService;

if (currentTenant === Tenant.Ionos) {
	instance = new IonosConsent();
} else {
	instance = new StratoConsent();
}

export default instance;
