<script lang="ts">
	import termsAcceptedService from "../../termsAcceptedService";
	import greetingService from "../../greetingService";
	import AcceptTerms from "./AcceptTerms.svelte";
	import Greeting from "./Greeting.svelte";
	import AddFirstFile from "./AddFirstFile.svelte";
</script>

<section>
	{#if !$greetingService}
	<Greeting/>
	{:else if !$termsAcceptedService}
	<AcceptTerms/>
	{:else}
	<AddFirstFile/>
	{/if}
</section>

<style>
	section {
		display: grid;

		text-align: center;
	}

	:global(.media-desktop) section {
		padding-top: calc(2 * var(--gap));
	}
</style>
