<script lang="ts">
	import LanguageSelector from "../i18n/LanguageSelector.svelte";
	import { t } from "svelte-i18n";
	import mediaService from "../ui/mediaService";
	import consentService from "../consentService";
	import { getValue } from "../subscription";
	import { referrerLinkBuilder } from "../tracking/referrerLinkBuilder";
	import { ActionId } from "../tracking/actionId";

	function showCookies() {
		consentService.set({
			...getValue(consentService),
			seen: false,
		});
	}

	let aboutHref: string;
	$: aboutHref = referrerLinkBuilder($t("aboutUrl"), ActionId.ABOUT_LINK);
</script>

<footer class="text-smaller {$mediaService.classNames}">
	{#if $mediaService.desktop}
		<footer-element class="text-bold button text-only-small">
			<LanguageSelector/>
		</footer-element>
		<footer-element class="text-bold">
			<a class="button text-only-small" href={$t("imprintUrl")} rel="noopener" target="_blank">{$t("imprint")}</a>
		</footer-element>
		<footer-element class="text-bold">
			<a class="button text-only-small" href={$t("privacyUrl")} rel="noopener" target="_blank">{$t("privacy")}</a>
		</footer-element>
		{#if consentService.isRequired}
		<footer-element class="text-bold">
			<button class="text-only-small" on:click={showCookies} data-qa="show-cookies">{$t("cookies")}</button>
		</footer-element>
		{/if}
		<footer-element class="text-bold">
			<a class="button text-only-small" href={$t("termsUrl")} rel="noopener" target="_blank">{$t("terms")}</a>
		</footer-element>
		<footer-element class="text-bold about-hidrive">
			<a class="button text-only-small" href={aboutHref} rel="noopener" target="_blank">{$t("about")}</a>
		</footer-element>
	{:else}
		<footer-element class="text-bold button text-only">
			<LanguageSelector/>
		</footer-element>
		<footer-element class="text-bold">
			<a class="button text-only" href={$t("imprintUrl")} rel="noopener" target="_blank">{$t("imprint")}</a>
		</footer-element>
		<footer-element class="text-bold">
			<a class="button text-only" href={$t("privacyUrl")} rel="noopener" target="_blank">{$t("privacy")}</a>
		</footer-element>
		{#if consentService.isRequired}
		<footer-element class="text-bold">
			<button class="text-only" on:click={showCookies} data-qa="show-cookies">{$t("cookies")}</button>
		</footer-element>
		{/if}
		<footer-element class="text-bold">
			<a class="button text-only" href={$t("termsUrl")} rel="noopener" target="_blank">{$t("terms")}</a>
		</footer-element>
		<footer-element class="text-bold about-hidrive">
			<a class="button secondary" href={aboutHref} rel="noopener" target="_blank">{$t("about")}</a>
		</footer-element>
	{/if}
</footer>

<style>
	footer {
		background: var(--theme-background);
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 0 calc(5 * var(--gap));
	}

	footer-element {
		max-width: 100%;

		text-align: center;

		margin-bottom: var(--gap);
	}

	footer.media-desktop {
		position: absolute;
		bottom: var(--gap);
		right: var(--gap);

		flex-direction: row;
		align-items: center;

		padding: 0 var(--half-gap);

		border: 1px solid #ddd;
		border-radius: var(--button-border-radius);
		box-shadow: var(--box-shadow);
		overflow: hidden;
	}

	footer.media-desktopLarge {
		bottom: calc(3 * var(--gap));
		right: calc(2 * var(--gap));
	}

	.media-desktop footer-element {
		display: flex;
		align-items: center;
		background: var(--theme-background);
		margin-bottom: 0;
		min-width: auto;
	}

	a {
		display: block;
		padding: 0 calc(var(--gap) * 2);
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		line-height: var(--button-small-height);
	}

	.media-desktop a {
		padding: 0 var(--gap);
	}

	.about-hidrive {
		width: 250px;
		margin-top: var(--gap);
	}

	.about-hidrive > a {
		line-height: calc(var(--button-height) - 2 * var(--button-border-width)) ;
	}

	.media-desktop .about-hidrive {
		width: auto;
		margin-top: 0;
	}

	.media-desktop .about-hidrive > a {
		line-height: var(--button-small-height);
	}
</style>
