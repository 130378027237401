import { derived } from "svelte/store";
import { t, number as localizeNumber } from "svelte-i18n";

/**
 * Format bytes in a human-readable format with given precision
 */
export default derived(
	[
		t,
		localizeNumber,
	],
	([$t, $localizeNumber]) => (bytes: number, precision: number = 0): string => {
		const UNITS = [
			$t("file.size.unit.B"),
			$t("file.size.unit.KB"),
			$t("file.size.unit.MB"),
			$t("file.size.unit.GB"),
		];

		if (bytes === 0) {
			return `0 ${UNITS[0]}`;
		}

		let formatOptions: Intl.NumberFormatOptions = {
			minimumFractionDigits: precision || undefined,
		};

		const base = 1024;

		const exponent = Math.floor(Math.log(bytes) / Math.log(base));
		const unit = UNITS[exponent];
		const numberInUnit = parseFloat((bytes / Math.pow(base, exponent)).toFixed(precision));

		return `${$localizeNumber(numberInUnit, formatOptions)} ${unit}`;
	},
);
