// errors
type ApiErrorResult = {
	readonly status: number;
	readonly statusText: string;
}

export class ApiError extends Error {
	public readonly status: number;
	public readonly statusText: string;

	constructor(response: ApiErrorResult, message: string) {
		super(message);

		this.status = response.status;
		this.statusText = response.statusText;
	}
}

export class CancelError extends Error {
	constructor() {
		super("USER_CANCEL");
	}
}

export const apiPrefix = "/api";

/**
 * Returns stringified url parameters
 *
 * This serializer replaces axios' default implementation where some characters
 * are explicilty _not_ encoded
 * see https://github.com/axios/axios/issues/3316
 */
export const paramsSerializer = (params: Record<string, string|number|boolean> = {}) => {
	return Object.entries(params)
			.map(([key, value]) =>`${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
			.join("&");
};
