enum BackgroundPosition {
	Bottom = "bottom",
	BottomLeft = "bottom left",
	BottomRight = "bottom right",
	Left = "left",
	LeftBottom = "left bottom",
	LeftTop = "left top",
	Right = "right",
	RightBottom = "right bottom",
	RightTop = "right top",
	Top = "top",
	TopLeft = "top left",
	TopRight = "top right",
}

export default BackgroundPosition;
