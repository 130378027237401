<script lang="ts">
	import { t } from "svelte-i18n";
	import Icon, { IconEnum } from "../../ui/icon/Icon.svelte";
	import Toast from "./Toast.svelte";
	import { Type as ToastType } from "./ToastType";

	import copyToClipboard, { isSupported } from "../../copyToClipboard";

	export let url: string;

	let copySuccess: boolean;
	let copyError: boolean;
	async function copy() {
		copySuccess = false;
		copyError = false;

		try {
			await copyToClipboard(url);
			copySuccess = true;
		} catch (ex) {
			copyError = true;
		}
	}
</script>

{#if isSupported()}
	<div class="toast-anchor" aria-live="assertive">
		<Toast
			message="{$t("send.copySuccess")}"
			type={ToastType.positive}
			visible={copySuccess}
		/>
		<Toast
			message="{$t("send.copyError")}"
			type={ToastType.negative}
			visible={copyError}
		/>
	</div>
	<button class="icon-only-button copy-trigger" on:click={copy} title={$t("send.copyLink")} data-qa="copy-link">
		<Icon icon={IconEnum.Copy}/>
	</button>
{/if}

<style>
	.copy-trigger {
		font-size: calc(1em + var(--half-gap));
		flex: 0 0 auto;
	}

	.copy-trigger:not(:active) {
		color: var(--theme-secondary);
	}

	.toast-anchor {
		position: relative;

		right: 101px;
		top: 63px;
	}
</style>
