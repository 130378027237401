<script lang="ts">
	import { onMount, onDestroy } from "svelte";
	import { t } from "svelte-i18n";
	import mediaService from "./mediaService";
	import Icon, { IconEnum } from "./icon/Icon.svelte";
	import ConsentSettings from "./ConsentSettings.svelte";
	import { ACCEPT_NONE, ACCEPT_ALL } from "./ConsentSelectionType";
	import consentService from "../consentService";
	import { process } from "../i18n/processLinks";

	onMount(() => {
		document.body.classList.add("overlayed");
	});

	onDestroy(() => {
		document.body.classList.remove("overlayed");
	});

	const introLinks = {
		cookieInfoLink: $t("cookieInfoUrl"),
		privacyLink: $t("privacyUrl"),
	};

	let settingsShown: boolean = false;
	let consentSettings: ConsentSettings;

	function onClick(event: Event) {
		const target: Element = event.target as Element;

		// eslint-disable-next-line no-script-url
		if (target.tagName === "A" && target.getAttribute("href") === "javascript:void(0)") {
			event.preventDefault();
		}

		if (target.matches('[data-name="settings"]')) {
			showSettings();
		} else if (target.matches('[data-name="acceptAll"]')) {
			acceptAll();
		} else if (target.matches('[data-name="reject"]')) {
			rejectAll();
		}
	}

	function showSettings() {
		settingsShown = true;
	}

	function back() {
		settingsShown = false;
	}

	function acceptSelection() {
		consentService.set({
			...consentSettings.getSettings(),
			seen: true,
		});
	}

	function acceptAll() {
		consentService.set({
			...ACCEPT_ALL,
			seen: true,
		});
	}

	function rejectAll() {
		consentService.set({
			...ACCEPT_NONE,
			seen: true,
		});
	}
</script>

<consent-overlay class="{$mediaService.classNames}">
	<consent-content on:click={onClick}>
		<p class="title text-bold text-larger">{$t("consentOverlay.title")}</p>
		<section class="body">
			<p>
				<span class="leadin">{@html $t("consentOverlay.introText")}</span>
				{#if settingsShown}
					<span class="explanation">{@html process($t("consentOverlay.introDetailExplanationText"), introLinks)}</span>
				{:else}
					<span class="details">{@html process($t("consentOverlay.introOverviewText"), introLinks)}</span>
				{/if}
			</p>
			<p class="accept-all-text">{@html process($t("consentOverlay.acceptAllText"), { })}</p>
			<div class="settings-wrapper">
				{#if settingsShown}
					<ConsentSettings bind:this={consentSettings} />
				{/if}
			</div>
			<section class="buttons">
				<div class="imprint-wrapper">
					<a href="{$t("imprintUrl")}" class="button text-only" data-qa="imprint" rel="noopener" target="_blank">{$t("imprint")}</a>
				</div>

				{#if settingsShown}
					<button class="button text-only back" data-qa="back" on:click={back}><Icon icon={IconEnum.BackArrow} /><span class="text">{$t("consentOverlay.back")}</span></button>
					<button class="button primary" data-qa="accept-selection" on:click={acceptSelection}>{$t("consentOverlay.acceptSelection")}</button>
				{:else}
					<button class="button text-only" data-qa="show-settings" on:click={showSettings}>{$t("consentOverlay.settings")}</button>
					<button class="button primary" data-qa="accept-all" on:click={acceptAll}>{$t("consentOverlay.acceptAll")}</button>
				{/if}
			</section>
		</section>
	</consent-content>
</consent-overlay>

<style>
	.title {
		margin-bottom: var(--half-gap);
	}

	.accept-all-text {
		margin-top: calc(2 * var(--gap));
	}

	.body {
		display: grid;
		grid-template-rows: auto auto 1fr auto;
	}

	.buttons {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;

		margin-top: calc(3 * var(--gap));
	}

	.media-narrow .buttons {
		flex-direction: column;
	}

	.back .text {
		margin-left: var(--gap);
	}

	.imprint-wrapper {
		display: flex;
		flex: 1;
	}

	button.primary {
		margin-left: calc(2 * var(--gap));
		width: 300px;
	}

	.media-narrow button.primary,
	.media-narrow button.text-only {
		margin: calc(var(--gap)) auto 0;
	}

	consent-overlay {
		display: flex;
		position: fixed;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		width: auto;
		height: auto;
		border: none;
		justify-content: center;
		align-items: center;
		background-color: var(--theme-backdrop);
		z-index: 100;
	}

	consent-content {
		display: grid;
		grid-template-rows: auto 1fr;

		overflow-y: auto;

		box-sizing: border-box;
		padding: calc(3 * var(--gap));
		width: 800px;
		border-radius: 30px;
		background-color: #fff;
	}

	.media-narrow consent-content {
		width: 100vw;
		height: 100vh;
		border-radius: 0;
	}
</style>
