<script lang="ts">
	import { t } from "svelte-i18n";
	import uploadService from "../uploadService";
	import quotaService, { QuotaStatus } from "../quotaService";
</script>
<div class="button-wrap">
	<button class="primary" on:click={() => uploadService.start()} data-qa="create-link" disabled={$quotaService.status !== QuotaStatus.OK}>
	{$t("createLink")}
	</button>
</div>

<style>
	.button-wrap {
		text-align: center;
		background-color: var(--theme-background);

		position: sticky;
		bottom: 0;
		left: 0;
		right: 0;
	}

	button {
		margin: 0 auto;
		width: 100%;
	}
</style>
