<script lang="ts">
	import { t } from "svelte-i18n";
	import { getZipUrl } from "../downloadService";

	export let downloadId: string;
</script>

<a
	class="button primary"
	target="_blank"
	title={$t("download.all")}
	href={getZipUrl(downloadId)}
	download
>{$t("download.all")}</a>

<style>
	.button {
		padding: 0 calc(var(--gap) * 2);
	}
</style>
