<script lang="ts">
	import { onMount } from "svelte";
	import uploadService, { UploadState } from "./uploadService";
	import Selector from "./selector/Selector.svelte";
	import Progress from "./progress/Progress.svelte";
	import Success from "./success/Success.svelte";
	import Error from "./error/Error.svelte";
	import scrollTopService from "./scrollTopService";

	onMount(() => {
		scrollTopService.start();
	});
</script>

<div class="content-wrapper">
{#if $uploadService.state === UploadState.NONE}
<Selector/>
{:else if $uploadService.state === UploadState.PROGRESS}
<Progress/>
{:else if $uploadService.state === UploadState.DONE}
<Success/>
{:else if $uploadService.state === UploadState.FAILED}
<Error/>
{/if}
</div>
