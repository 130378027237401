<style>
	.fa-icon {
		width: 1em;
		height: 1em;
		overflow: visible;
		display: inline-block;
	}
</style>

<script context="module" lang="ts">
// re-export icon enum for import convenience of the consumer
import { IconEnum } from "./iconMap";
export { IconEnum };
</script>

<script lang="ts">
	import type { IconData } from "./iconMap";
	import { getIcon } from "./iconMap";

	let classesToAdd: string = "";

	export let icon: IconEnum;
	export { classesToAdd as class };

	let usedIcon: IconData;

	$: {
		usedIcon = getIcon(icon);
	}
</script>

<svg
	aria-hidden="true"
	class="fa-icon {classesToAdd || ''}"
	role="img"
	xmlns="http://www.w3.org/2000/svg"
	viewBox="0 0 {usedIcon.width} {usedIcon.height}"
>
  <path fill="currentColor" d="{usedIcon.svgPathData}" />
</svg>
