<script lang="ts">
	import { t } from "svelte-i18n";
	import mediaService from "./mediaService";
	import consentService from "../consentService";
	import Icon, { IconEnum } from "./icon/Icon.svelte";
	import { ACCEPT_NONE } from "./ConsentSelectionType";
	import type { ConsentSelection } from "./ConsentSelectionType";

	let groups: Element;
	let showDetails: string = null;

	function onClick(event: Event) {
		const target: HTMLElement = (event.target as HTMLElement);
		const input: HTMLInputElement = (target.querySelector("input") as HTMLInputElement) || (target as HTMLInputElement);

		if (!input.matches("[name=detail]")) {
			return;
		}

		if (input.checked) {
			onDetailToggle(input.value);
		} else {
			onDetailToggle(null);
		}

		[...groups.querySelectorAll("[name=detail]")].forEach((el: HTMLInputElement) => {
			if (el !== input) {
				el.checked = false;
			}
		});
	}

	function onDetailToggle(detailName: string) {
		showDetails = detailName;
	}

	export function getSettings(): ConsentSelection {
		const checkboxes = [...groups.querySelectorAll('[type="checkbox"]')];
		const values: ConsentSelection = { ...ACCEPT_NONE };
		let el: Element;

		for (el of checkboxes) {
			const checkbox: HTMLInputElement = (el as HTMLInputElement);
			const name: string = checkbox.getAttribute("name");
			const value: boolean = checkbox.checked;
			if (name === "detail") {
				continue;
			}
			values[name] = value;
		}

		return values;
	}
</script>

<section class={$mediaService.classNames}>
	<form-groups bind:this={groups} on:click={onClick}>
		<form-group>
			<label class="styled checkbox">
				<input type="checkbox" name="technical" checked disabled>
				<span class="box"><Icon icon={IconEnum.Check} /></span>
				<span class="text text-larger">{$t("consentOverlay.type.technical")}</span>
			</label>
			<label class="styled toggle">
				<input type="checkbox" name="detail" value="technical">
				<Icon icon={IconEnum.Arrow} />
				<span class="text text-larger">{$t("consentOverlay.details")}</span>
			</label>
		</form-group>
		{#if showDetails === "technical"}
		<p class="mobile-detail">{$t("consentOverlay.details.technical")}</p>
		{/if}
		<form-group>
			<label class="styled checkbox">
				<input type="checkbox" name="statistics" checked={$consentService.statistics}>
				<span class="box"><Icon icon={IconEnum.Check} /></span>
				<span class="text text-larger">{$t("consentOverlay.type.statistics")}</span>
			</label>
			<label class="styled toggle">
				<input type="checkbox" name="detail" value="statistics">
				<Icon icon={IconEnum.Arrow} />
				<span class="text text-larger">{$t("consentOverlay.details")}</span>
			</label>
		</form-group>
		{#if showDetails === "statistics"}
		<p class="mobile-detail">{$t("consentOverlay.details.statistics")}</p>
		{/if}
		<form-group>
			<label class="styled checkbox">
				<input type="checkbox" name="marketing" checked={$consentService.marketing}>
				<span class="box"><Icon icon={IconEnum.Check} /></span>
				<span class="text text-larger">{$t("consentOverlay.type.marketing")}</span>
			</label>
			<label class="styled toggle">
				<input type="checkbox" name="detail" value="marketing">
				<Icon icon={IconEnum.Arrow} />
				<span class="text text-larger">{$t("consentOverlay.details")}</span>
			</label>
		</form-group>
		{#if showDetails === "marketing"}
		<p class="mobile-detail">{$t("consentOverlay.details.marketing")}</p>
		{/if}
		<form-group>
			<label class="styled checkbox">
				<input type="checkbox" name="partner" checked={$consentService.partner}>
				<span class="box"><Icon icon={IconEnum.Check} /></span>
				<span class="text text-larger">{$t("consentOverlay.type.partner")}</span>
			</label>
			<label class="styled toggle">
				<input type="checkbox" name="detail" value="partner">
				<Icon icon={IconEnum.Arrow} />
				<span class="text text-larger">{$t("consentOverlay.details")}</span>
			</label>
		</form-group>
		{#if showDetails === "partner"}
		<p class="mobile-detail">{$t("consentOverlay.details.partner")}</p>
		{/if}
	</form-groups>
	<p class="details">
		{#if showDetails === "technical"}
			{$t("consentOverlay.details.technical")}
		{:else if showDetails === "statistics"}
			{$t("consentOverlay.details.statistics")}
		{:else if showDetails === "marketing"}
			{$t("consentOverlay.details.marketing")}
		{:else if showDetails === "partner"}
			{$t("consentOverlay.details.partner")}
		{/if}
	</p>
</section>

<style>
	section {
		display: flex;
		flex-direction: column;
		margin-top: calc(5 * var(--gap));
	}

	form-groups {
		display: flex;
		flex-direction: row;
	    justify-content: space-between;
	}

	form-group {
		display: flex;
		flex-direction: column;
	}

	.media-narrow form-groups {
		flex-direction: column;
	}

	.media-narrow form-group {
		margin-top: var(--half-gap);
		flex-direction: row;
		justify-content: space-between;
	}

	.details {
		margin-top: calc(2 * var(--gap));
	}

	.media-narrow .details, .mobile-detail {
		display: none;
	}

	.media-narrow .mobile-detail {
		display: block;
		margin: var(--gap) 0;
	}

	.toggle {
		margin-top: calc(3 * var(--gap));
	}

	.media-narrow .toggle {
		margin-top: 0;
	}
</style>
