// follows the breakpoints of chrome devtools
const MOBILE_BREAKPOINT = 425;
const TABLET_BREAKPOINT = 768;

export default (): "mobile" | "tablet" | "desktop" => {
	if (window.innerWidth <= MOBILE_BREAKPOINT) {
		return "mobile";
	} else if (window.innerWidth <= TABLET_BREAKPOINT) {
		return "tablet";
	}

	return "desktop";
};
