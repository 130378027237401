import type { operations, components } from "../api/__schema";
import { apiPrefix, ApiError } from "../api/common";
import axios from "axios";

// GET /$id/index
export type ApiIndexEntry = components["schemas"]["RspIndexEntry"];
export type GetIndexPathParam = operations["get_index"]["parameters"]["path"]["id"]

export async function getIndex(id: GetIndexPathParam): Promise<ApiIndexEntry[]> {
	const path = `${apiPrefix}/${id}/index`;
	try {
		const response = await axios.get(path);
		return response.data;
	} catch (error) {
		throw new ApiError(error.request, `error on GET ${path}`);
	}
}
