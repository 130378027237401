/**
 * Load an image by url
 */
export default (url: string): Promise<HTMLImageElement> => {
	return new Promise((resolve) => {
		const img: HTMLImageElement = new Image();

		img.src = url;
		img.onload = () => resolve(img);
	});
};
