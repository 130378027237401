<script lang="ts">
	import quota, { QuotaStatus } from "../quotaService";
	import QuotaText from "./QuotaText.svelte";

	$: usagePercent = Math.floor(($quota.size * 100) / $quota.limit) || 0;
	$: usagePercentCss = Math.min(usagePercent, 100);
	$: quotaExceeded = $quota.status === QuotaStatus.EXCEEDED;
</script>

<section class:quota-exceeded={quotaExceeded}>
	<QuotaText/>
	<!--
	We use our own markup because vendors have completely different vendor
	prefixed selectors for meter elements. Also Firefox does not fully remove
	its indicator rendering when using appearance: none;

	The element is hidden because the message element should give sufficient
	explanation.
	-->
	<div aria-hidden="true" class="quota">
	   <div class="percent"
		   style="width: {usagePercentCss}%"
	   >
	   </div>
	</div>
</section>

<style>
	.quota {
		--quota-bar-height: 20px;
		--quota-bar-border-radius: calc(var(--quota-bar-height) / 2);

		height: var(--quota-bar-height);
		display: block;
		background: var(--theme-background);
		overflow: hidden;

		border: 2px solid var(--border-on-background-color);
		border-radius: var(--quota-bar-border-radius);

		margin-top: calc(var(--gap) - 2px);

		box-sizing: border-box;
	}

	.percent{
		height: 100%;
		border-radius: 0 var(--quota-bar-border-radius) var(--quota-bar-border-radius) 0;
		background: var(--theme-secondary);
	}

	.quota-exceeded .percent {
		background: var(--theme-negative);
	}
</style>
