<script lang="ts">
	import config from "../../configService";
	import Icon, { IconEnum } from "../../ui/icon/Icon.svelte";
	import successImg from "./success.svg";
	import uploadService from "../uploadService";
	import queueService from "../queueService";
	import mediaService from "../../ui/mediaService";
	import SmallAd from "../../ads/Small.svelte";
	import UrlBox from "./UrlBox.svelte";
	import { t } from "svelte-i18n";
	import { path as tenantPath } from "../../tenant";

	let url: string;

	$: url = `${config.RECEIVER_DOMAIN}${tenantPath}${$uploadService.uploadId}`;

	function shareMore() {
		uploadService.reset();
		queueService.clear();
	}
</script>

<section>
	<span class="teaser text-bold">{$t("send.success")}</span>
	<span class="caption text-muted">
		{$t("send.linkHint")}
	</span>

	<div class="url-container">
		<UrlBox {url}/>
	</div>

	<div class="visual-container">
		{#if $mediaService.desktop}
			<img src="{successImg}" alt=""/>
		{:else}
			<SmallAd/>
		{/if}
	</div>

	<button
		class="text-only"
		on:click={shareMore}
		data-qa="start-new-share"
		aria-live="assertive"
	>
		<Icon icon={IconEnum.PlusSquare}/>
		{$t("send.shareMore")}
	</button>
</section>

<style>
	section {
		display: grid;
		grid-template-rows: auto auto auto 1fr auto;

		text-align: center;
	}

	.url-container {
		margin: 0 auto var(--gap);
	}

	.visual-container {
		margin: calc(2 * var(--gap)) auto;
	}

	button {
		margin: 0 auto;
	}
</style>
