<script type="ts">
	import { t } from "svelte-i18n";

	import formatBytes from "../../../ui/formatBytes";

	import quotaService from "../../quotaService";
	import greetingService from "../../greetingService";

	import greetingImage from "./greeting.svg";

	function confirmGreeting() {
		greetingService.setGreeted(true);
	}
</script>

<section>
	<p class="teaser text-bold content-width-limit">{$t("welcome")}</p>
	<div class="caption content-width-limit">{@html $t("greeting", { values: { limit: $formatBytes($quotaService.limit) } })}</div>
	<div class="visual-container">
		<img src={greetingImage} alt="">
	</div>
	<button class="primary" on:click={confirmGreeting} data-qa="greeting-seen">{$t("getStarted")}</button>
</section>

<style>
	section {
		display: grid;
		grid-template-rows: auto auto 1fr auto;
	}

	button {
		display: block;
		margin: calc(2 * var(--gap)) auto 0;
		width: 100%;
	}
</style>
