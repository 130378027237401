const PLACEHOLDER_REGEX = /^[$][$][A-Z0-9_]+[$][$]$/;

/**
 * Get a config value with a placeholder variable changed to null.
 * @param {String} value raw config value
 * @return Config value or null if it contains a config placeholder.
 */
export default function getSanitizedConfigValue(value: string): string {
	if (PLACEHOLDER_REGEX.test(value)) {
		value = null;
	}

	return value;
}
