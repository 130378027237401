<script lang="ts">
	import { t } from "svelte-i18n";
	import uploadService from "../uploadService";

	let isConfirming: boolean = false;
</script>

{#if !isConfirming}
<button
	class="cancel-button secondary"
	on:click={() => { isConfirming = true; }}
	data-qa="cancel-upload"
>
	{$t("cancelAndBack")}
</button>
{:else}
<div class="button-group" aria-live="assertive">
	<button
		class="secondary"
		on:click={() => { isConfirming = false; }}
		data-qa="cancel-upload-abort"
	>
		{$t("confirm.no")}
	</button>
	<button
		class="primary" on:click={() => uploadService.cancel()}
		data-qa="cancel-upload-confirm"
	>
		{$t("confirm.yes")}
	</button>
</div>
{/if}

<style>
	.cancel-button,
	.button-group {
		width: var(--button-max-width);
		margin: auto;
	}

	.button-group {
		display: flex;
		flex-direction: row;
	}

	.button-group button {
		flex: 1 1 50%;
	}

	.button-group :first-child{
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;

		margin-right: 1px;
	}

	.button-group :last-child{
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;

		margin-left: 1px;
	}
</style>
