import type { ConsentResult } from "../consentServiceTypes";

const COOKIE_VERSION = 1;
const COOKIE_AGE = 60 * 60 * 24 * 365 * 10;
const COOKIE_NAME = "ionos_hidrive_share_consent";
const COOKIE_VALUE_MATCHER = new RegExp(`(?:^|\\s|;)${COOKIE_NAME}=([^;]+)`);

export function setConsent(result: ConsentResult) {
	const cookieValue = encodeURIComponent(JSON.stringify({ ...result, version: COOKIE_VERSION }));
	document.cookie = `${COOKIE_NAME}=${cookieValue}; path=/; max-age=${COOKIE_AGE}; samesite=strict; `;
}

export function getConsent(): ConsentResult | null {
	const match = document.cookie.match(COOKIE_VALUE_MATCHER);

	if (!match) {
		return null;
	}

	let parsedValue;
	const [, cookieValue] = match;
	try {
		parsedValue = JSON.parse(decodeURIComponent(cookieValue));
	} catch (ex) {
		return null;
	}

	const { version, ...result } = parsedValue;
	if (version !== COOKIE_VERSION) {
		return null;
	}

	return result;
}
