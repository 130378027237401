import type { ISubscription } from "../../subscription";
import { derived } from "svelte/store";
import QueueService from "../queueService";

export const QUOTA_LIMIT_BYTES = 2147483648; // 2gb

export enum QuotaStatus {
	OK,
	EXCEEDED,
}

/**
 * Generic interface of Quota
 */
export interface IQuotaState {
	limit: number,
	size: number,
	status: QuotaStatus,
}

export class QuotaService implements ISubscription<IQuotaState> {
	private readonly subscription;
	public subscribe;

	constructor() {
		this.subscription = derived(
			QueueService,
			this.updateQuota,
		);
		this.subscribe = this.subscription.subscribe;
	}

	private updateQuota(queue: File[]): IQuotaState {
		const quota = {
			limit: QUOTA_LIMIT_BYTES,
			size: 0,
			status: QuotaStatus.OK,
		};

		if (queue.length === 0) {
			return quota;
		}

		queue.forEach(item => {
			quota.size = quota.size + item.size;
		});

		if (quota.size > QUOTA_LIMIT_BYTES) {
			quota.status = QuotaStatus.EXCEEDED;
		}

		return quota;
	}
}
