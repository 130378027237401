<script lang="ts">
	import QuotaFileSelect from "./QuotaFileSelect.svelte";
	import List from "./List.svelte";
	import Start from "./Start.svelte";
	import Intro from "./intro/Intro.svelte";
	import queueService from "../queueService";
</script>

<section class="content-width-limit">
	{#if $queueService.length}
	<div class="file-select-list-wrapper content-height-limit-mobile">
		<div class="file-select">
			<QuotaFileSelect/>
		</div>
		<List/>
		<div class="button-wrapper">
			<Start/>
		</div>
	</div>
	{:else}
		<Intro/>
	{/if}
</section>

<style>
	section {
		min-height: 0;
		width: 100%;
		margin: 0 auto;

		display: grid;
		grid-template-rows: 1fr;
	}

	.file-select {
		margin-bottom: var(--gap);
	}

	.file-select-list-wrapper {
		display: grid;
		grid-template-rows: auto 1fr auto;
		min-height: 0;
	}

	.button-wrapper {
		margin-top: calc(2 * var(--gap));
	}
</style>
