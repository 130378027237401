<script lang="ts">
	import { t } from "svelte-i18n";
	import IonosSmall from "./ionos/Small.svelte";
	import StratoSmall from "./strato/Small.svelte";
	import { currentTenant, Tenant } from "../tenant";

	import { referrerLinkBuilder } from "../tracking/referrerLinkBuilder";
	import { ActionId } from "../tracking/actionId";

	let href: string;
	$: href = referrerLinkBuilder($t("mainAdUrl"), ActionId.SMALL_AD);
</script>

<a {href} rel="noopener" target="_blank">
	{#if currentTenant === Tenant.Ionos}
	<IonosSmall/>
	{:else}
	<StratoSmall/>
	{/if}
</a>
