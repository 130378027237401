<script class="ts">
	import mediaService from "../ui/mediaService";
</script>

<main class={$mediaService.classNames}>
	<slot/>
</main>

<style>
	main {
		background: var(--theme-background);

		display: grid;
		grid-template-rows: auto 1fr;

		min-height: var(--mobile-min-height);
	}


	main.media-desktop {
		position: absolute;
		top: var(--gap);
		left: var(--gap);
		height: 660px;
		width: 480px;
		min-height: 0;

		overflow: hidden;
		border-radius: 25px;
		box-shadow: var(--box-shadow);
	}

	main.media-desktop.media-narrow {
		width: 450px;
	}

	main.media-desktopLarge {
		top: calc(var(--gap) * 7);
		left: calc(var(--gap) * 10);

		height: 700px;
	}
</style>
