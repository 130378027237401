export type { ApiIndexEntry as IndexEntry } from "../api";
import { getIndex as apiGetIndex } from "../api";

export async function getIndex(id: string) {
	const apiResponse = await apiGetIndex(id);

	return apiResponse.map(entry => ({
		...entry,
		filename: decodeURIComponent(entry.filename),
	}));
}
