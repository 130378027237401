export interface IconData {
	width: number,
	height: number,
	svgPathData: string,
}

export enum IconEnum {
	AngleUp,
	Arrow,
	ArrowCircleRight,
	BackArrow,
	Bug,
	Check,
	Copy,
	DownloadFile,
	Drive,
	File,
	FileUpload,
	Flag,
	Loading,
	Plus,
	PlusSquare,
	Remove,
	Share,
	ShareAlt,
}

const iconMap: { [key in IconEnum]: IconData } = {
	[IconEnum.AngleUp]: require("@fortawesome/free-solid-svg-icons/faAngleUp"),
	[IconEnum.Arrow]: require("@fortawesome/free-solid-svg-icons/faChevronUp"),
	[IconEnum.ArrowCircleRight]: require("@fortawesome/free-solid-svg-icons/faArrowCircleRight"),
	[IconEnum.BackArrow]: require("@fortawesome/free-solid-svg-icons/faArrowLeft"),
	[IconEnum.Bug]: require("@fortawesome/free-solid-svg-icons/faBug"),
	[IconEnum.Check]: require("@fortawesome/free-solid-svg-icons/faCheck"),
	[IconEnum.Copy]: require("@fortawesome/free-regular-svg-icons/faCopy"),
	[IconEnum.DownloadFile]: require("@fortawesome/free-solid-svg-icons/faDownload"),
	[IconEnum.Drive]: require("@fortawesome/free-regular-svg-icons/faHdd"),
	[IconEnum.File]: require("@fortawesome/free-regular-svg-icons/faFile"),
	[IconEnum.FileUpload]: require("@fortawesome/free-solid-svg-icons/faFileUpload"),
	[IconEnum.Flag]: require("@fortawesome/free-regular-svg-icons/faFlag"),
	[IconEnum.Loading]: require("@fortawesome/free-solid-svg-icons/faCircleNotch"),
	[IconEnum.Plus]: require("@fortawesome/free-solid-svg-icons/faPlus"),
	[IconEnum.PlusSquare]: require("@fortawesome/free-regular-svg-icons/faPlusSquare"),
	[IconEnum.Remove]: require("@fortawesome/free-solid-svg-icons/faTrashAlt"),
	[IconEnum.Share]: require("@fortawesome/free-solid-svg-icons/faShare"),
	[IconEnum.ShareAlt]: require("@fortawesome/free-solid-svg-icons/faShareAlt"),
};

export function getIcon(icon: IconEnum): IconData {
	return iconMap[icon];
}
