<script lang="ts">
	import logoImage from "./header_logo.svg";
</script>
<header class="flex-center">
	<img src="{logoImage}" alt="">
</header>

<style>
header {
	background: var(--header-color);

	height: var(--header-height);

	position: sticky;
	top: 0;
	/* Keep header in front of content when scrolling */
	z-index: 20;
}

img {
	height: 22px;
}
</style>
