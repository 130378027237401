import type { ITrackingService } from "./trackingServiceTypes";

import IonosTracker from "./ionos";

import { currentTenant, Tenant } from "../tenant";

let instance: ITrackingService;
if (currentTenant === Tenant.Ionos) {
	instance = new IonosTracker();
} else {
	instance = {};
}

export default instance;
