
export function isSupported(): boolean {
	return !!(navigator.clipboard && navigator.clipboard.writeText);
}

export default async function copyToClipboard(str: string): Promise<void> {
	if (!isSupported()) {
		throw new Error("Clipboard not supported or access prevented.");
	}

	return await navigator.clipboard.writeText(str);
}
