/**
 * Replace link fragments <a name> by <a name href> with either a URL or "javascript:void(0)"
 * @param {String} text input text with <a name>
 * @param {Record} mappings of names to URLs
 * @return Text with links enriched by href attributes
 */
export function process(text: string, identifiers: Record<string, string>): string {
	return text.replace(/<a name="([^"]+)">/g, (match, name) => {
		// eslint-disable-next-line no-script-url
		let href = "javascript:void(0)";

		if (name in identifiers) {
			href = identifiers[name];
		}

		return `<a href="${href}" target="_blank" rel="noopener" data-name="${name}" data-qa="${name}">`;
	});
}
