import type { Readable } from "svelte/store";
import type MediaQueryResult from "./MediaQueryResult";

import watchMedia from "svelte-media";

const desktop = {
	width: 730,
	height: 725,
};

const desktopLarge = {
	width: 900,
	height: 900,
};

const mediaqueries = {
	desktop: `(min-width: ${desktop.width}px) and (min-height: ${desktop.height}px)`,
	desktopLarge: `(min-width: ${desktopLarge.width}px) and (min-height: ${desktopLarge.height}px)`,
	narrow: "(max-width: 1023px)",
	tall: "(min-height: 1024px)",
};

// force typecast since svelte-media does not provide generic function interface
export default watchMedia(mediaqueries) as any as Readable<MediaQueryResult>;
