import greetingService from "../greetingService";
import termsAcceptedService from "../termsAcceptedService";
import uploadService, { UploadState } from "../uploadService";
import queueService from "../queueService";

/**
 * This services subscribes to all services that trigger UI "scene" changes and
 * scrolls to the top of the page so that the customer always sees the relevant
 * content even if they've scrolled down previously.
 */
export default class ScrollTopService {
	private lastUploadState: UploadState | null = null;
	private lastQueueEmpty: boolean = true;
	private started: boolean = false;

	start() {
		greetingService.subscribe(() => { this.scroll(); });
		termsAcceptedService.subscribe(() => { this.scroll(); });
		uploadService.subscribe((uploadStatus) => { this.onUploadChange(uploadStatus); });
		queueService.subscribe((queue) => { this.onQueueChange(queue); });
		this.started = true;
	}

	onUploadChange({ state }) {
		if (this.lastUploadState !== state) {
			this.lastUploadState = state;
			this.scroll();
		}
	}

	onQueueChange(queue) {
		const queueEmpty = queue.length === 0;

		if (this.lastQueueEmpty !== queueEmpty) {
			this.lastQueueEmpty = queueEmpty;
			this.scroll();
		}
	}

	scroll() {
		if (!this.started) {
			return;
		}

		window.scrollTo(0, 0);
	}
}
