import { Subscription } from "../../subscription";

import type { IConsentService, ConsentResult } from "../consentServiceTypes";

import { getConsent, setConsent } from "./repository";

export default class IonosConsent implements IConsentService {
	private consent: ConsentResult;

	public readonly isRequired = true;

	private subscription: Subscription<ConsentResult>;
	public subscribe;

	constructor() {
		this.consent = getConsent() || {
			technical: false,
			marketing: false,
			statistics: false,
			partner: false,
			seen: false,
		};

		this.subscription = new Subscription(() => Object.freeze(this.consent));
		this.subscribe = (arg) => this.subscription.subscribe(arg);
	}

	set(newValue: ConsentResult) {
		const changedEntries = Object.entries(newValue)
			.filter(([key, value]) => value !== this.consent[key]);
		const changes: Partial<ConsentResult> = Object.fromEntries(changedEntries);

		if (Object.keys(changes).length === 0) {
			return;
		}

		const newState: ConsentResult = {
			...this.consent,
			...changes,
		};

		setConsent(newState);

		this.consent = newState;
		this.subscription.notify();
	}
}
