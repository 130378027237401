import { Subscription } from "../../subscription";

import type { IConsentService, ConsentResult } from "../consentServiceTypes";

export default class StratoConsent implements IConsentService {
	private consent: ConsentResult = {
		technical: false,
		marketing: false,
		statistics: false,
		partner: false,
		seen: true,
	};

	public readonly isRequired = false;

	private subscription: Subscription<ConsentResult>;
	public subscribe;

	constructor() {
		this.subscription = new Subscription(() => Object.freeze(this.consent));
		this.subscribe = (arg) => this.subscription.subscribe(arg);
	}

	set(_value: ConsentResult) {
		// service state should be immutable
	}
}
