
export type ConsentSelection = {
	technical: boolean,
	marketing: boolean,
	statistics: boolean,
	partner: boolean,
};

export const ACCEPT_NONE: ConsentSelection = Object.freeze({
	technical: true,
	marketing: false,
	statistics: false,
	partner: false,
});

export const ACCEPT_ALL: ConsentSelection = Object.freeze({
	technical: true,
	marketing: true,
	statistics: true,
	partner: true,
});
