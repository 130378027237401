<script lang="ts">
	import { onMount } from "svelte";

	// Workaround for iOS providing wrong vh value, not subtracting the
	// navigation bar height, effectively hiding action button behind it.
	//
	// See this for details: https://www.bram.us/2020/05/06/100vh-in-safari-on-ios/
	//
	// Inspired by https://css-tricks.com/the-trick-to-viewport-units-on-mobile/

	function applyFix() {
		const availHeight = document.documentElement.clientHeight;
		document.documentElement.style.setProperty("--mobile-min-height", `${availHeight}px`);
	}

	onMount(applyFix);
</script>

<svelte:window on:resize={applyFix}/>
