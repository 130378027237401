<script lang="ts">
	import { t } from "svelte-i18n";
	import { Route } from "tinro";
	import DesktopAd from "./ads/Desktop.svelte";
	import Header from "./layout/Header.svelte";
	import Main from "./layout/Main.svelte";
	import Footer from "./layout/Footer.svelte";
	import Send from "./send/Send.svelte";
	import Receive from "./receive/Receive.svelte";
	import VhFix from "./vhFix.svelte";
	import mediaService from "./ui/mediaService";
	import { path as tenantPath } from "./tenant";
	import ConsentOverlay from "./ui/ConsentOverlay.svelte";
	import consentService from "./consentService";
</script>

<svelte:head>
	<title>{$t("title")}</title>
</svelte:head>

{#if consentService.isRequired && !$consentService.seen}
	<ConsentOverlay/>
{/if}

<VhFix/>
{#if $mediaService.desktop}
	<DesktopAd/>
{/if}
<Main>
	<Route path="{tenantPath}*">
		<Route path="/" redirect="{tenantPath}upload"/>

		<Route path="/*" firstmatch>
			<Route path="/upload">
				<Header/>
				<Send/>
			</Route>
			<Route path="/:id">
				<Header/>
				<Receive/>
			</Route>
			<Route fallback redirect="{tenantPath}upload"/>
		</Route>
	</Route>
</Main>

{#if window.self === window.top}
<Footer/>
{/if}
