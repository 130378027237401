<script lang="ts">
	import { t } from "svelte-i18n";
	import Icon, { IconEnum } from "../../../ui/icon/Icon.svelte";
	import Toast from "../Toast.svelte";
	import { Type as ToastType } from "../ToastType";

	export let url: string;

	enum KnownErrors {
		Type = "TypeError",
		Permission = "NotAllowedError"
	}

	let lastShareError: string;
	async function onClick() {
		try {
			lastShareError = null;
			await navigator.share({
				title: $t("send.share.native.title"),
				url,
			});
		} catch (ex) {
			if (ex.name === "AbortError") {
				// ignore user cancel
				return;
			}

			if (!ex.name) {
				lastShareError = "unknown";
				return;
			}

			lastShareError = ex.name;
		}
	}

	function matchesUnknownError(rawError: string) {
		return !!rawError && !Object.values(KnownErrors).includes(rawError as any);
	}

	function matchesError(rawError: string, knownError: KnownErrors) {
		return rawError === knownError;
	}
</script>

<share-native>
	<div class="toast-anchor" aria-live="assertive">
		<Toast
			message="{$t("send.share.native.error")}"
			type={ToastType.negative}
			visible={matchesUnknownError(lastShareError)}
		/>
		<Toast
			message="{$t("send.share.native.error.permission")}"
			type={ToastType.negative}
			visible={matchesError(lastShareError, KnownErrors.Permission)}
		/>
		<Toast
			message="{$t("send.share.native.error.type")}"
			type={ToastType.negative}
			visible={matchesError(lastShareError, KnownErrors.Type)}
		/>
	</div>
	<button class="icon-only-button share-trigger" on:click={onClick} title={$t("send.share.native")}>
		<Icon icon={IconEnum.ShareAlt}/>
	</button>
</share-native>

<style>
	.toast-anchor {
		position: relative;

		top: calc(var(--button-height) + 3 * var(--gap));
		right: 147px;
	}

	.share-trigger {
		font-size: calc(1em + var(--half-gap));
		flex: 0 0 auto;
	}

	.share-trigger:not(:active) {
		color: var(--theme-secondary);
	}
</style>
