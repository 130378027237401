<script lang="ts">
	import { t } from "svelte-i18n";
	import queueService from "../queueService";

	let labelClasses: string = "";
	export { labelClasses as class };

	function onChange(event: Event) {
		const target: HTMLInputElement = (event.target as HTMLInputElement);
		const files: File[] = [...target.files];
		queueService.add(files);
		// Unset to allow picking the same file again:
		// for consistency and to be able to re-add the same file after it was
		// removed from the queue
		target.value = "";
	}
</script>

<label role="button" class={labelClasses} title={$t("send.selectFiles")}>
	<slot></slot>
	<input type="file" on:change={onChange} multiple aria-hidden="true"/>
</label>

<style>
	label {
		overflow: hidden;
	}

	@media (pointer: fine), (pointer: none) {
		label {
			cursor: pointer;
		}
	}

	input {
		display: none;
	}
</style>
