<script lang="ts">
	import { t } from "svelte-i18n";
	import Icon, { IconEnum } from "../ui/icon/Icon.svelte";
</script>

<section class="flex-center">
	<span class="loader">
		<Icon class="animate-spin text-large" icon={IconEnum.Loading}/>
	</span>
	<span class="label">{$t("receive.loading")}</span>
</section>

<style>
	section {
		flex-direction: column;
	}

	.label {
		margin-top: var(--gap);
	}

	.loader {
		color: var(--theme-secondary);
	}
</style>
