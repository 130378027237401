import { addMessages, init, getLocaleFromNavigator } from "svelte-i18n";
import { currentTenant, Tenant } from "../tenant";

import de from "./de.json";
import en from "./en.json";
import es from "./es.json";
import fr from "./fr.json";
import it from "./it.json";
import nl from "./nl.json";
import sv from "./sv.json";

import ionosDe from "./IONOS-de.json";
import ionosEn from "./IONOS-en.json";
import ionosEs from "./IONOS-es.json";
import ionosFr from "./IONOS-fr.json";
import ionosIt from "./IONOS-it.json";
import ionosNl from "./IONOS-nl.json";
import ionosSV from "./IONOS-sv.json";


export default function initialize() {
	addMessages("de", de);
	addMessages("en", en);
	addMessages("es", es);
	addMessages("fr", fr);
	addMessages("it", it);
	addMessages("nl", nl);
	addMessages("sv", sv);

	if (currentTenant === Tenant.Ionos) {
		addMessages("de", ionosDe);
		addMessages("en", ionosEn);
		addMessages("es", ionosEs);
		addMessages("fr", ionosFr);
		addMessages("it", ionosIt);
		addMessages("nl", ionosNl);
		addMessages("sv", ionosSV);
	}

	const navigatorLocale = getLocaleFromNavigator().split("-")[0];
	const requestedLocale = new URLSearchParams(location.search).get("lang");

	init({
		fallbackLocale: "de",
		initialLocale: requestedLocale || navigatorLocale,
	});
}
