export default (src: string): Promise<void> => {
	return new Promise(res => {
		const scriptEl: HTMLScriptElement = document.createElement("script");

		scriptEl.onload = () => res();
		scriptEl.src = src;

		document.head.appendChild(scriptEl);
	});
};
