export const REFERRER_PARAM_NAME = "hd_org_ref";
export const ACTION_PARAM_NAME = "CTA_HD";

/**
 * Append document's referrer and an action identifier as query parameters to the passed URL.
 * Existing parameters are not replaced if they're coincidentally named the same.
 *
 * @param {String} urlString the URL to be extended
 * @param {String} [actionId] action identifier of a CTA
 * @return {String} the altererd URL
 */
export function referrerLinkBuilder(urlString: string, actionId: string = null): string {
	const url = new URL(urlString);
	const query = url.searchParams;
	const referrer = document.referrer;

	if (referrer && !query.has(REFERRER_PARAM_NAME)) {
		query.set(REFERRER_PARAM_NAME, referrer);
	}

	if (actionId !== null && !query.has(ACTION_PARAM_NAME)) {
		query.set(ACTION_PARAM_NAME, actionId);
	}

	return url.toString();
}
