import { ISubscription, Subscription } from "../../subscription";

export class GreetingService implements ISubscription<boolean> {
	private greeted: boolean = false;

	private subscription: Subscription<boolean>;
	public subscribe;

	constructor() {
		this.subscription = new Subscription(() => this.greeted);
		this.subscribe = (arg) => this.subscription.subscribe(arg);
	}

	setGreeted(value: boolean) {
		if (value !== this.greeted) {
			this.greeted = value;
			this.subscription.notify();
		}
	}
}
