import { apiPrefix } from "../../api/common";

/**
 * Get url to download a single file from a share
 *
 * @param {string} id id of the share
 * @param {string} token token of the download entry
 */
export function getUrl(id: string, token: string) {
	return `${apiPrefix}/${id}/file/${token}`;
}

/**
 * Get url to download the contents of a share as zip
 *
 * @param {string} id id of the share
 */
export function getZipUrl(id: string) {
	return `${apiPrefix}/${id}/archive`;
}
