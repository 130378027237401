import consentService from "../../consentService";
import loadGoogleTracker from "./loadGoogleTracker";
import type { ITrackingService } from "../trackingServiceTypes";

export default class IonosTrackingService implements ITrackingService {
	private googleLoaded = false;

	constructor() {
		consentService.subscribe(({ partner }) => {
			if (!partner) {
				return;
			}

			if (!this.googleLoaded) {
				loadGoogleTracker();
				this.googleLoaded = true;
			}
		});
	}
}
