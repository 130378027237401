<script lang="ts">
	import CopyLinkIcon from "./CopyLinkIcon.svelte";
	import Share from "./share/Index.svelte";


	export let url: string;

	let displayUrl: string;
	$: displayUrl = url.replace(/^https?:\/\//, "");
</script>

<div class="box flex-center text-selectable">
	<div class="url-container text-ellipsis">
		<a
			href="{url}"
			target="_blank"
			class="text-smaller"
		>
			{displayUrl}
		</a>
	</div>
	<CopyLinkIcon {url}/>
	<Share {url}/>
</div>

<style>
	.box {
		color: var(--theme-secondary);
		height: 56px;

		border: 2px solid var(--border-on-background-color);
		border-radius: 28px;

		padding: 0 var(--half-gap) 0 calc(2 * var(--gap));

		box-sizing: border-box;
		/* fit live urls including tenant sub path without ellipsis */
		min-width: 300px;
		max-width: 350px;
	}

	.box a {
		font-size: 18px;
		font-weight: 600;
	}

	.url-container {
		flex: 1 1 100%;
		margin-right: var(--half-gap);
	}
</style>
