<script lang="ts">
	const radius = 36;
	const stroke = 8;

	const normalizedRadius = radius - 0.5 * stroke;
	const circumference = normalizedRadius * 2 * Math.PI;

	export let max: number;
	export let value: number;

	let ratio: number;
	let strokeDashoffset: number = 0;
	$: {
		if (max === 0) {
			ratio = 0;
		} else {
			ratio = value / max;
		}
		strokeDashoffset = circumference - ratio * circumference;
	}
</script>

<svg
	height={radius * 2}
	width={radius * 2}
	role="progressbar"
	aria-valuemin="0"
	aria-valuemax="100"
	aria-valuenow={Math.floor(ratio * 100)}
	>
	<circle
		class="background"
		stroke="currentColor"
		fill="transparent"
		stroke-width={ stroke }
		r={ normalizedRadius }
		cx={ radius }
		cy={ radius }
	/>
	<circle
		class="foreground"
		stroke="currentColor"
		fill="transparent"
		stroke-width={ stroke }
		stroke-dasharray={ circumference }
		stroke-dashoffset={ strokeDashoffset }
		stroke-linecap="round"
		r={ normalizedRadius }
		cx={ radius }
		cy={ radius }
		transform="rotate(-90 { radius } { radius })"
	/>
</svg>

<style>
	circle.foreground {
		color: var(--theme-secondary);
		transition: stroke-dashoffset 0.2s ease-in;
	}
	circle.background {
		color: var(--theme-background-muted);
	}
</style>
