import axios, { CancelTokenSource } from "axios";
import { ISubscription, SubscribeHandler, Subscription, getValue } from "../../subscription";
import queue from "../queueService";
import uploadFiles from "./uploadFiles";

export enum UploadState {
	NONE,
	PROGRESS,
	DONE,
	FAILED,
}

export type UploadProgress = {
	state: UploadState,
	filesDone: number,
	bytesDone: number,
	uploadId?: string,
	error?: Error,
}

export class UploadService implements ISubscription<UploadProgress> {
	private progress: UploadProgress;
	private cancelTokenSource: CancelTokenSource;

	private subscription: Subscription<UploadProgress>;
	public subscribe: SubscribeHandler<UploadProgress>;

	constructor() {
		this.resetProgress();

		this.subscription = new Subscription(() => this.progress);
		this.subscribe = (arg) => this.subscription.subscribe(arg);
	}

	/**
	 * Update progress state with given properties
	 */
	private updateProgress(newProps: Partial<UploadProgress>) {
		this.progress = {
			...this.progress,
			...newProps,
		};
		this.subscription.notify();
	}

	/**
	 * Reset the progress
	 */
	private resetProgress() {
		this.progress = {
			state: UploadState.NONE,
			filesDone: 0,
			bytesDone: 0,
		};
	}

	/**
	 * Upload files from upload queue.
	 * Resolves on success, rejects on error.
	 */
	private async uploadFiles(): Promise<void> {
		const files: File[] = getValue(queue);

		this.resetProgress();

		this.updateProgress({
			state: UploadState.PROGRESS,
		});

		this.cancelTokenSource = axios.CancelToken.source();

		const onProgress = deltaBytes => {
			this.updateProgress({
				bytesDone: this.progress.bytesDone + deltaBytes,
			});
		};

		const cancelToken = this.cancelTokenSource.token;
		for await (const fileUploadProgress of uploadFiles(files, cancelToken, onProgress)) {
			this.updateProgress({
				state: UploadState.PROGRESS,
				filesDone: fileUploadProgress.filesDone,
				uploadId: fileUploadProgress.uploadId,
			});
		}

		this.updateProgress({
			state: UploadState.DONE,
		});
	}

	/**
	 * Start the upload process for current content of upload queue.
	 * Always resolves.
	 *
	 * @returns {Promise<void>}
	 */
	async start(): Promise<void> {
		try {
			await this.uploadFiles();
		} catch (error) {
			if (error && error.message === "USER_CANCEL") {
				this.reset();
				return;
			}

			this.updateProgress({
				state: UploadState.FAILED,
				error,
			});
		}
	}

	/**
	 * Cancel upload
	 */
	cancel() {
		if (!this.cancelTokenSource) {
			return;
		}
		this.cancelTokenSource.cancel();
	}

	/**
	 * Reset upload state
	 */
	reset() {
		this.resetProgress();
		this.subscription.notify();
	}
}
