import { ISubscription, SubscribeHandler, Subscription } from "../../subscription";

export class QueueService implements ISubscription<File[]> {
	private queue: Set<File> = new Set();

	private subscription: Subscription<File[]>;
	public subscribe: SubscribeHandler<File[]>;

	constructor() {
		this.subscription = new Subscription(() => [...this.queue]);
		this.subscribe = (arg) => this.subscription.subscribe(arg);
	}

	add(files: File | File[]) {
		for (const file of [].concat(files)) {
			this.queue.add(file);
		}
		this.subscription.notify();
	}

	remove(file: File) {
		if (this.queue.delete(file)) {
			this.subscription.notify();
		}
	}

	clear() {
		this.queue.clear();
		this.subscription.notify();
	}
}
