<script lang="ts">
	import { t } from "svelte-i18n";
	import quota, { QuotaStatus } from "../quotaService";
	import queueService from "../queueService";
	import formatBytes from "../../ui/formatBytes";
	import Icon, { IconEnum } from "../../ui/icon/Icon.svelte";

	let quotaExceeded: boolean = false;
	$: quotaExceeded = $quota.status === QuotaStatus.EXCEEDED;
</script>

<div class="quota-text flex-center">
	<Icon icon={IconEnum.File}/>
	<span class="file-count">
		{#if $queueService.length === 1}
		{$t("send.quota.count.one")}
		{:else}
		{$t("send.quota.count.many", { values: { count: $queueService.length } })}
		{/if}
	</span>
	<span class="spacer text-muted">
		&#124;
	</span>
	<Icon icon={IconEnum.Drive}/>
	<span class="file-size">
		<span class="size" class:text-negative={quotaExceeded}>
			{$formatBytes($quota.size, 2)}
		</span>
		{$t("send.quota.used")}
		<span class="limit">
			{$formatBytes($quota.limit)}
		</span>
	</span>
</div>

<style>
	.file-size,
	.file-count {
		margin-left: var(--half-gap);
	}

	.spacer {
		color: var(--spacer-color);
		margin-right: var(--half-gap);
		margin-left: var(--half-gap);
	}
</style>
