import type { CancelToken } from "axios";
import { postFinalize, postNew } from "../api";
import nameConflictResolver from "./nameConflictResolver";
import uploadFile, { UploadProgressHandler } from "./uploadFile";

export enum UploadFilesStep {
	UPLOAD_CREATED,
	UPLOAD_PATCHING,
	UPLOAD_PATCHED,
	UPLOAD_DONE,
}

export type UploadFilesProgress = {
	step: UploadFilesStep;
	filesDone: number;
	uploadId: string;
};

export default async function* uploadFiles(
	files: File[],
	cancelToken: CancelToken,
	onProgress: UploadProgressHandler,
): AsyncIterable<UploadFilesProgress> {
	let uploadDescriptor = await postNew({ cancelToken });

	const { id: uploadId } = uploadDescriptor;

	yield {
		step: UploadFilesStep.UPLOAD_CREATED,
		filesDone: 0,
		uploadId: uploadDescriptor.id,
	};

	const destinationNameProvider = nameConflictResolver();

	let filesDone = 0;
	for (const file of files) {
		const dst = destinationNameProvider(file.name);

		const fileUploadOptions = {
			...uploadDescriptor,
			file,
			dst,
			cancelToken,
			onProgress,
		};

		for await (const _ of uploadFile(fileUploadOptions)) {
			// proceed to next chunk
		}

		filesDone++;

		yield {
			step: filesDone === files.length ? UploadFilesStep.UPLOAD_PATCHED : UploadFilesStep.UPLOAD_PATCHING,
			filesDone,
			uploadId,
		};
	}

	await postFinalize({
		...uploadDescriptor,
		cancelToken,
	});

	yield {
		step: UploadFilesStep.UPLOAD_DONE,
		filesDone,
		uploadId,
	};
}
