export enum Tenant {
	Strato = "STRATO",
	Ionos = "IONOS",
}

const tenantFolders = {
	[Tenant.Ionos]: "i",
	[Tenant.Strato]: "",
};

export const [currentTenant] = Object.entries(tenantFolders)
	// probe longest folder first
	.sort(([, a], [, b]) => b.length - a.length)
	.find(([, folder]) => {
		if (folder.length === 0) {
			return true;
		}
		return new RegExp(`^/${folder}(/|$)`).test(location.pathname);
	});

export const path = `/${tenantFolders[currentTenant]}/`.replace(/\/\//, "/");
