import { ApiPatchRequest, postPatch } from "../api";

export const CHUNK_SIZE = 15 * 1024 * 1024;

export type UploadProgressHandler = (deltaBytes: number) => void;

type UploadFileOptions = Omit<ApiPatchRequest, "body" | "offset" | "progressCallback"> & {
	file: File;
	onProgress: UploadProgressHandler;
};

/**
 * Upload given file in chunks via generator
 *
 * @example
 * for await (const _ of uploadFile({ ... })) {
 *     // function yields after each successful chunk
 * }
 */
export default async function* uploadFile({
	file,
	onProgress,
	...apiOptions
}: UploadFileOptions): AsyncIterable<void> {
	if (!file.size) {
		// upload empty file
		await postPatch({
			...apiOptions,
			body: file,
			offset: 0,
			progressCallback: () => {},
		});
		return;
	}

	let lastChunkProgress = 0;
	function onChunkProgress(ev: ProgressEvent) {
		onProgress(ev.loaded - lastChunkProgress);
		lastChunkProgress = ev.loaded;
	}

	let chunkCursor = 0;
	while (chunkCursor < file.size) {
		await postPatch({
			...apiOptions,
			body: file.slice(chunkCursor, chunkCursor + CHUNK_SIZE),
			offset: chunkCursor,
			progressCallback: onChunkProgress,
		});

		yield;

		chunkCursor += CHUNK_SIZE;
		lastChunkProgress = 0;
	}
}
