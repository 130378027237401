<script lang="ts">
	import { locale, locales, t } from "svelte-i18n";
	import Icon, { IconEnum } from "../ui/icon/Icon.svelte";
	import mediaService from "../ui/mediaService";
</script>

<language-selector class="flex-center {$mediaService.classNames}">
	<div class="flag">
		<Icon icon={IconEnum.Flag}/>
	</div>
	<span class="current-lang">{$t("locale")}</span>
	<select class="select text-bold" bind:value={$locale}>
		{#each $locales as currentLocale}
		<option value={currentLocale}>{$t("locale", { locale: currentLocale })}</option>
		{/each}
	</select>
	<div class="suffix">
		<Icon icon={IconEnum.AngleUp}/>
	</div>
</language-selector>

<style>
	language-selector {
		position: relative;

		height: 100%;
		box-sizing: border-box;

		border-radius: var(--button-border-radius);

		color: var(--theme-secondary);
	}

	language-selector.media-desktop {
		color: var(--text-on-background-color);
		padding: var(--gap);
	}

	language-selector:active {
		background-color: var(--button-active-background);
		color: var(--text-on-primary-color);
	}

	.flag {
		margin-right: calc(0.5 * var(--gap));
		margin-left: calc(0.5 * var(--gap));
		display: flex;
	}

	span {
		margin-right: calc(0.5 * var(--gap));
	}

	select {
		/* "hide" via opacity, use 100% width/height as clickable area */
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		opacity: 0;
		overflow: hidden;

		appearance: none;
		-webkit-appearance: none;

		background-color: transparent;
		border: none;
		margin: 0;
		padding: 0;

		outline: none;

		font-size: inherit;
		font-family: inherit;

		color: var(--text-on-background-color);
	}

	@media (pointer: fine), (pointer: none) {
		select {
			cursor: pointer;
		}
	}

	.suffix {
		display: flex;
	}
</style>
