import "./global.css";
import "./buttons.css";
import "./forms.css";
import "./animation.css";
import "./list.css";
import "./i18n";
import "./trackingService";
import "./consentPageReloadService";

import Index from "./Index.svelte";

const appRoot = document.querySelector("#app");

if (appRoot) {
	new Index({
		target: appRoot,
	});
}
