import consentService from "../consentService";

/**
 * Reloads the page when a change in consent cannot be be reverted, e.g.
 * because 3rd party tracking code was injected into the page.
 */
export default class ConsentPageReloadService {
	hasTracking = false;

	constructor() {
		consentService.subscribe(consent => {
			if (this.hasTracking && !consent.partner) {
				// consent to tracking was withdrawn
				window.location.reload();
				return;
			}

			this.hasTracking = consent.partner;
		});
	}
}
