<script lang="ts">
	import { onMount } from "svelte";
	import loadImage from "./loadImage";
	import BackgroundPosition from "./backgroundPosition";

	// image that should be shown while the real image is loaded
	export let preloadImage: string;
	// the real image to be displayed
	export let image: string;
	// image positioning in the background
	export let backgroundPosition: BackgroundPosition = BackgroundPosition.TopLeft;

	let preloadDone: boolean = false;
	onMount(() => loadImage(image).then(() => { preloadDone = true; }));
</script>

<image-preloader>
	<div
		class="image"
		style="background-position: {backgroundPosition}; {preloadDone ? `background-image: url(${image});` : ''}"
		></div>
	<div
		class="image preload"
		class:hidden={preloadDone}
		style="background-position: {backgroundPosition}; background-image: url({preloadImage});"
		></div>
</image-preloader>

<style>
	image-preloader {
		display: block;

		position: relative;
	}

	.image {
		background-size: cover;

		left: 0;
		right: 0;
		top: 0;
		bottom: 0;

		position: absolute;
	}

	.preload {
		transition: opacity .1s ease-out;
	}

	.preload.hidden {
		opacity: 0;
	}
</style>
