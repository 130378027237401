<script lang="ts">
	import { t } from "svelte-i18n";

	import Icon, { IconEnum } from "../../ui/icon/Icon.svelte";
	import Quota from "./Quota.svelte";
	import FileSelect from "./FileSelect.svelte";
</script>

<section class="flex-center">
	<div class="quota-spacer">
		<Quota/>
	</div>
	<div class="flex-center">
		<FileSelect class="button text-only">
			<Icon icon={IconEnum.Plus}/>
			{$t("send.selectMoreFiles")}
		</FileSelect>
	</div>
</section>

<style>
	section {
		background-color: var(--theme-background);

		flex-direction: column;
		align-items: stretch;
	}

	.quota-spacer {
		margin-bottom: calc(var(--gap) - 2px);
	}
</style>
