import type { operations, components } from "../api/__schema";
import { apiPrefix, ApiError, CancelError, paramsSerializer } from "../api/common";
import axios, { CancelToken } from "axios";

// generic types
type TokenAuth = { token: string };
type WithCancelToken = { cancelToken: CancelToken };

// POST /new
export type ApiNewResponse = components["schemas"]["RspNew"]

export async function postNew({
	cancelToken,
}: WithCancelToken): Promise<ApiNewResponse> {
	const path = `${apiPrefix}/new`;
	try {
		const response = await axios.request({
			url: path,
			method: "post",
			cancelToken,
		});
		return response.data;
	} catch (error) {
		if (axios.isCancel(error)) {
			throw new CancelError();
		}
		throw new ApiError(error.request, `error on POST ${path}`);
	}
}

// POST /$id/patch
type PatchFileBody = { body: Blob }
type SchemaPatchFileQueryParam = operations["patch"]["parameters"]["query"];
// API wants to interpret 'dst' as OsString for raw bytes
// -> Map 'dst' to a TypeScript compatible type
type PatchFileQueryParam = Omit<SchemaPatchFileQueryParam, "dst"> & {
	dst: string,
}
type PatchFilePathParam = operations["patch"]["parameters"]["path"];
type PatchFileProgress = {
	progressCallback: (a: ProgressEvent) => void;
}
// compose all options required for POST /{id}/patch into type
export type ApiPatchRequest =
	PatchFileQueryParam &
	PatchFilePathParam &
	PatchFileBody &
	TokenAuth &
	WithCancelToken &
	PatchFileProgress;

export type ApiPatchResponse = components["schemas"]["RspPatch"]

export async function postPatch({
	token,
	id,
	dst,
	offset,
	body,
	cancelToken,
	progressCallback,
}: ApiPatchRequest): Promise<ApiPatchResponse> {
	const path = `${apiPrefix}/${id}/patch`;
	try {
		const response = await axios.request({
			method: "post",
			url: path,
			headers: {
				"x-auth-token": token,
			},
			params: {
				dst,
				offset,
			},
			paramsSerializer,
			data: body,
			cancelToken,
			onUploadProgress: progressCallback,
		});
		return response.data;
	} catch (error) {
		if (axios.isCancel(error)) {
			throw new CancelError();
		}
		throw new ApiError(error.request, `error on POST ${path}`);
	}
}

// POST /$id/finalize
type FinalizePathParam = operations["finalize"]["parameters"]["path"];
export type ApiFinializeRequest = FinalizePathParam & TokenAuth & WithCancelToken;

export async function postFinalize({
	token,
	id,
	cancelToken,
}:ApiFinializeRequest): Promise<void> {
	const path = `${apiPrefix}/${id}/finalize`;
	try {
		await axios.request({
			url: path,
			method: "post",
			headers: {
				"x-auth-token": token,
			},
			cancelToken,
		});
	} catch (error) {
		if (axios.isCancel(error)) {
			throw new CancelError();
		}
		throw new ApiError(error.request, `error on POST ${path}`);
	}
}
