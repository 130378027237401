<script lang="ts">
	import { t } from "svelte-i18n";
	import type { IndexEntry } from "../indexService";
	import { getUrl } from "../downloadService";
	import Icon, { IconEnum } from "../../ui/icon/Icon.svelte";
	import formatBytes from "../../ui/formatBytes";

	export let downloadId: string;
	export let entries: IndexEntry[];
</script>

<file-list>
	{#each entries as entry}
		<a target="_blank"  title={$t("download.file", { values: { filename: entry.filename, size: $formatBytes(entry.size, 1) } })} href={getUrl(downloadId, entry.token)} download>
			<file-list-item>
				<file-list-icon>
					<Icon icon={IconEnum.File}/>
				</file-list-icon>
				<file-list-caption>
					<div class="text-ellipsis">{entry.filename}</div>
					<div class="text-muted-on-muted">{$formatBytes(entry.size, 1)}</div>
				</file-list-caption>
				<file-list-action>
					<button class="icon-only-button">
						<Icon icon={IconEnum.DownloadFile}/>
					</button>
				</file-list-action>
			</file-list-item>
		</a>
	{/each}
</file-list>

<style>
	a {
		text-decoration: none;
		color: var(--text-on-background-color);
	}

	@media (pointer: fine), (pointer: none) {
		file-list-action {
			display: none;
		}

		file-list-item:hover file-list-action {
			display: initial;
		}
	}
</style>
